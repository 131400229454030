<template>
    <div id="page-number-manager-sim-range" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to SIM Ranges</router-link>

                    <h1 class="heading">Edit SIM Range</h1>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <div class="form-group">
                            <div class="form-controls">
                                <app-input
                                    v-model="sim_range.SIMRangeStart"

                                    label="SIM Range Start"

                                    :required="true"

                                    :error="errors.fields.SIMRangeStart"

                                    @change="errors.fields.SIMRangeStart = null"
                                />
                            </div>

                            <div class="form-controls">
                                <app-input
                                    v-model="sim_range.SIMRangeEnd"

                                    label="SIM Range End"

                                    :required="true"

                                    :error="errors.fields.SIMRangeEnd"

                                    @change="errors.fields.SIMRangeEnd = null"
                                />
                            </div>

                            <div class="form-controls">
                                <app-input
                                    v-model="sim_range.IMSIRangeStart"

                                    label="IMSI Range Start"

                                    :required="true"

                                    :error="errors.fields.IMSIRangeStart"

                                    @change="errors.fields.IMSIRangeStart = null"
                                />
                            </div>

                            <div class="form-controls">
                                <app-input
                                    v-model="sim_range.IMSIRangeEnd"

                                    label="IMSI Range End"

                                    :required="true"

                                    :error="errors.fields.IMSIRangeEnd"

                                    @change="errors.fields.IMSIRangeEnd = null"
                                />
                            </div>
                        </div>

                        <app-error v-model="errors.show" :message="errors.message" />

                        <button class="btn btn-primary btn-save" :disabled="!is_changed" @click="save">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'

import errMessage from '@/helpers/errMessage'

import SIMRangeValidator from '@/validators/sim-range-validator'

import cloneDeep from 'lodash/cloneDeep'

const EMPTY_SIM_RANGE = {
    SIMRangeStart: null,
    SIMRangeEnd: null,

    IMSIRangeStart: null,
    IMSIRangeEnd: null,
}

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
    },

    data() {
        return {
            sim_range:          cloneDeep(EMPTY_SIM_RANGE),
            original_sim_range: cloneDeep(EMPTY_SIM_RANGE),

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.sim_range_uuid) {
                this.getSIMRangeDetails()
            }
        },

        getSIMRangeDetails() {
            this.loading = true

            const params = {
                UUID: this.sim_range_uuid,
                SPID: this.current_spid,
            }

            this.$store.dispatch('api_simdb/GetSIMRangeByUUID', params)
                .then(response => {
                    this.sim_range =          cloneDeep(response)
                    this.original_sim_range = cloneDeep(response)

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        validation() {
            let is_valid = true

            this.errors = {
                show: false,
                message: '',

                fields: {},
            }

            const fields = {
                SIMRangeStart: {
                    rule: 'SIMRange',
                    message: 'Please, enter valid SIM Range Start',
                },

                SIMRangeEnd: {
                    rule: 'SIMRange',
                    message: 'Please, enter valid SIM Range End',
                },

                IMSIRangeStart: {
                    rule: 'IMSIRange',
                    message: 'Please, enter valid IMSI Range Start',
                },

                IMSIRangeEnd: {
                    rule: 'IMSIRange',
                    message: 'Please, enter valid IMSI Range End',
                },
            }

            for (const key in fields) {
                if (SIMRangeValidator.isRuleExists(fields[key].rule)) {
                    if (SIMRangeValidator.isInvalid(fields[key].rule, this.sim_range[key], fields[key].message)) {
                        this.errors.fields[key] = SIMRangeValidator.getError()
                        is_valid = false
                    }
                }
            }

            if (!this.errors.fields.SIMRangeStart && !this.errors.fields.SIMRangeEnd) {
                if (+this.sim_range.SIMRangeStart >= +this.sim_range.SIMRangeEnd) {
                    this.errors.show = true
                    this.errors.message = '"SIM Range End" must be greater than "SIM Range Start"'

                    is_valid = false
                }
            }

            if (!this.errors.fields.IMSIRangeStart && !this.errors.fields.IMSIRangeEnd) {
                if (+this.sim_range.IMSIRangeStart >= +this.sim_range.IMSIRangeEnd) {
                    this.errors.show = true
                    this.errors.message = '"IMSI Range End" must be greater than "IMSI Range Start"'

                    is_valid = false
                }
            }

            return is_valid
        },

        save() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    UUID: this.sim_range_uuid,
                    SPID: this.current_spid,

                    SIMRangeStart: this.sim_range.SIMRangeStart,
                    SIMRangeEnd: this.sim_range.SIMRangeEnd,

                    IMSIRangeStart: this.sim_range.IMSIRangeStart,
                    IMSIRangeEnd: this.sim_range.IMSIRangeEnd,
                }

                /**
                 * @todo
                 */
                // import('./test-sim-range.json')
                this.$store.dispatch('api_simdb/UpdateSIMRange', params)
                    .then(response => {
                        this.sim_range =          cloneDeep(response)
                        this.original_sim_range = cloneDeep(response)

                        this.errors.show = false
                        this.errors.message = ''

                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },

        reset() {
            this.errors = {
                show: false,
                message: '',

                fields: {},
            }

            if (!this.sim_range_uuid) {
                this.original_sim_range = cloneDeep(EMPTY_SIM_RANGE)
            }

            this.sim_range = cloneDeep(this.original_sim_range)
        }
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'number-manager-sim-ranges'
                ? this.referrer
                : { name: 'number-manager-sim-ranges' }
        },

        sim_range_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        is_changed() {
            let is_changed = false

            for (const key in EMPTY_SIM_RANGE) {
                is_changed = this.sim_range[key] != this.original_sim_range[key]

                if (is_changed) {
                    break
                }
            }

            return is_changed
        },
    },

    watch: {
        uuid() {
            if (this.sim_range_uuid) {
                this.getSIMRangeDetails()
            } else {
                this.reset()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-number-manager-sim-range {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .form-group {
            margin-bottom: 24px;

            .form-controls {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    #page-number-manager-sim-range {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .board {
            padding: 16px 8px;
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}
</style>