import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    SIMRange: {
        name: 'SIM Range',
        required: true,
        format: /^\d{16,}$/,
    },

    IMSIRange: {
        name: 'IMSI Range',
        required: true,
        format: /^\d{15,}$/,
    },
})