<template>
    <app-dialog-info
        class="app-dialog-add-sim-range"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Add SIM range</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <div class="form-group">
                <div class="form-controls">
                    <app-dropdown-select
                        v-model="service_provider"
                        :options="service_providers"

                        key-value="value"
                        key-title="title"

                        label="Service Provider"

                        :predictive-search="true"

                        :required="true"

                        :error="errors.fields.service_provider"

                        @change="errors.fields.service_provider = null"
                    />
                </div>
            </div>

            <div class="btns">
                <app-btn-upload
                    class="btn-primary"

                    accept=".pgp"

                    :disabled="!is_service_provider_selected"

                    @upload="addNewRange"
                >Upload file</app-btn-upload>

                <button class="btn btn-primary btn-cancel" @click="close">Cancel</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appDropdownSelect from '@/components/app-dropdown-select'
import appBtnUpload from '@/components/app-btn-upload-v2'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appDropdownSelect,
        appBtnUpload,
    },

    data() {
        return {
            service_provider: null,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        addNewRange(file) {
            this.loading = true

            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = () => {
                /**
                 * Remove the "data:application/octet-stream;base64," header data,
                 * as this is Javascript DOM-specific header, not raw base64 data
                 * API requires just the base64 data, not including any browser headers
                 */
                let b64EncodedData = reader.result.toString().replace(/^data:(.*,)?/, '')

                if ((b64EncodedData.length % 4) > 0) {
                    b64EncodedData += '='.repeat(4 - (b64EncodedData.length % 4))
                }

                const params = {
                    SPID: this.service_provider,
                    Data: b64EncodedData,
                }

                this.$store.dispatch('api_simdb/UploadSIMRange', params)
                    .then(() => {
                        this.$store.dispatch('addToast', {
                            message: 'SIM Range was successfully created',
                            type: 'success',
                            delay: 5000,
                        })

                        this.$emit('confirm')

                        this.loading = false
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }

            reader.onerror = () => {
                this.errors.show = true
                this.errors.message = reader.error

                this.loading = false
            }
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        ...mapGetters([
            'available_service_providers',
        ]),

        service_providers() {
            return this.available_service_providers.map(provider => ({
                value: provider.SPID,
                title: provider.Name,
                // title: `${ provider.Name } (${ provider.SPID })`,
            }))
        },

        is_service_provider_selected() {
            return this.service_provider !== null
        },

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-add-sim-range {
    .app-dialog-body {
        .form-group {
            width: 100%;

            margin-bottom: 24px;

            .form-controls {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .btns {
            margin-top: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-add-sim-range {
        .app-dialog-body {
            .btns {
                padding: 0;
            }
        }
    }
}
</style>