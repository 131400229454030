<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Edit SIM Range" @close-sidebar="closeSidebar">
                <sim-range
                    :uuid="sim_range_uuid"

                    ref="sim-range"

                    @close-sidebar="closeSidebar"
                    @saved="getSIMRanges"
                />
            </app-sidebar-details>
        </template>

        <div class="page-number-manager-sim-ranges">
            <app-loader v-if="loading" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="heading-box">
                            <h1 class="heading">SIM Ranges</h1>

                            <div class="dropdown-select-box" v-if="is_main_spid">
                                <span>Filter by:</span>

                                <app-dropdown-select
                                    v-model="filter.service_provider"
                                    :options="service_providers"

                                    key-value="value"
                                    key-title="title"

                                    label="Service Provider"

                                    :predictive-search="true"

                                    @change="getSIMRanges(1)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <app-error v-model="errors.show" :message="errors.message" />

                        <app-table
                            class="sim-ranges-table"

                            :sort-value="filter.sort"
                            :sort-options="sort"
                            @sort-change="onSortChange"

                            :cols="cols"
                            :rows="rows"

                            :selected-row="sim_range_uuid"

                            @edit-sim-range="onEditSIMRange"
                            @remove-sim-range="onRemoveSIMRange"
                        />

                        <div class="actions">
                            <app-pagination
                                v-if="pagination.total"

                                v-model="pagination.page"

                                :total="pagination.total"
                                :limit="pagination.limit"
                                :page-range="pagination.range"

                                @change="onPageChange"
                            />

                            <button class="btn btn-primary btn-add-new-range"
                                v-if="show_add_new_range_button"

                                @click="onAddSIMRange"
                            >Add new range</button>
                        </div>
                    </div>
                </div>
            </div>

            <app-dialog-add-sim-range
                v-if="dialogs.sim_range.add.show"
                v-model="dialogs.sim_range.add.show"

                @confirm="onDialogAddSIMRangeConfirm"
                @close="onDialogAddSIMRangeClose"
            />

            <app-dialog-sim-range-removal
                v-if="dialogs.sim_range.remove.show"
                v-model="dialogs.sim_range.remove.show"

                :uuid="dialogs.sim_range.remove.uuid"

                @confirm="onDialogSIMRangeRemovalConfirm"
                @close="onDialogSIMRangeRemovalClose"
            />
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import simRange from '@/views/number-manager/sim-range'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appBtnUpload from '@/components/app-btn-upload-v2'
import appDropdownSelect from '@/components/app-dropdown-select'

import appDialogAddSimRange from './components/app-dialog-add-sim-range'
import appDialogSimRangeRemoval from './components/app-dialog-sim-range-removal'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        simRange,

        appLoader,
        appError,
        appTable,
        appPagination,
        appBtnUpload,
        appDropdownSelect,

        appDialogAddSimRange,
        appDialogSimRangeRemoval,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc', // asc, desc
                },

                service_provider: null,
            },

            sort: [
                { field: 'SIMRangeStart',  title: 'SIM range start'  },
                { field: 'SIMRangeEnd',    title: 'SIM range end'    },
                { field: 'IMSIRangeStart', title: 'IMSI range start' },
                { field: 'IMSIRangeEnd',   title: 'IMSI range end'   },

                { field: 'CreatedAtNanos', title: 'Created at'       },
                { field: 'UpdatedAtNanos', title: 'Updated at'       },
            ],

            cols: [
                { key: 'SIMRangeStart',  title: 'SIM range start',  sort: { field: 'SIMRangeStart'  } },
                { key: 'SIMRangeEnd',    title: 'SIM range end',    sort: { field: 'SIMRangeEnd'    } },
                { key: 'IMSIRangeStart', title: 'IMSI range start', sort: { field: 'IMSIRangeStart' } },
                { key: 'IMSIRangeEnd',   title: 'IMSI range end',   sort: { field: 'IMSIRangeEnd'   } },

                { key: 'CreatedAtNanos', title: 'Created at',       sort: { field: 'CreatedAtNanos' } },
                { key: 'UpdatedAtNanos', title: 'Updated at',       sort: { field: 'UpdatedAtNanos' } },

                { key: 'edit-sim-range',   action: 'edit-sim-range',   icon: 'edit',   behavior: 'detached', },
                { key: 'remove-sim-range', action: 'remove-sim-range', icon: 'remove', behavior: 'detached', },
            ],

            sim_ranges: [],

            sim_range_uuid: null,

            is_mobile_mode: false,

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                sim_range: {
                    add: {
                        show: false,
                    },

                    remove: {
                        show: false,
                        uuid: null,
                    },
                },
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.filter.service_provider = this.current_spid

            this.getSIMRanges()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getSIMRanges()
        },

        onPageChange(page) {
            this.getSIMRanges(page)
        },

        getSIMRanges(page) {
            this.loading = true

            const params = {
                SPID: this.filter.service_provider,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            // import('./test-sim-ranges.json')
            this.$store.dispatch('api_simdb/GetSIMRangesPaginated', params)
                .then(response => {
                    this.sim_ranges = Array.isArray(response.SIMRanges) ? response.SIMRanges : []

                    if (response.PageInfo) {
                        this.pagination.page = response.PageInfo.PageNumber
                        this.pagination.total = response.PageInfo.TotalItemCount

                        if (this.$route.params.page != this.pagination.page
                            && !(
                                this.$route.params.page === undefined
                                && this.pagination.page === 1
                            )
                        ) {
                            this.$router.push({
                                name: this.$route.name,
                                params: { page: this.pagination.page },
                                replace: true,
                            })
                        }
                    }

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.sim_ranges = []

                    this.loading = false
                })
        },

        addNewRange(file) {
            this.loading = true

            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = () => {
                /**
                 * Remove the "data:application/octet-stream;base64," header data,
                 * as this is Javascript DOM-specific header, not raw base64 data
                 * API requires just the base64 data, not including any browser headers
                 */
                let b64EncodedData = reader.result.toString().replace(/^data:(.*,)?/, '')

                if ((b64EncodedData.length % 4) > 0) {
                    b64EncodedData += '='.repeat(4 - (b64EncodedData.length % 4))
                }

                const params = {
                    SPID: this.current_spid,
                    Data: b64EncodedData,
                }

                // import('./test-sim-range.json')
                this.$store.dispatch('api_simdb/UploadSIMRange', params)
                    .then(() => {
                        this.$store.dispatch('addToast', {
                            message: 'SIM Range was successfully created',
                            type: 'success',
                            delay: 5000,
                        })

                        this.getSIMRanges()
                    })
                    .catch(error => {
                        this.$store.dispatch('addToast', {
                            message: errMessage(error),
                            type: 'error',
                            delay: 5000,
                        })

                        this.loading = false
                    })
            }

            reader.onerror = () => {
                this.$store.dispatch('addToast', {
                    message: reader.error,
                    type: 'error',
                    delay: 5000,
                })

                this.loading = false
            }
        },

        onEditSIMRange(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'number-manager-sim-range-edit', params: { uuid: row.uuid } })
            } else {
                this.sim_range_uuid = row.uuid

                this.openSidebar()
            }
        },
        openSidebar() {
            this.$emit('open-sidebar')
        },
        closeSidebar() {
            this.sim_range_uuid = null

            this.$emit('close-sidebar')

            this.$refs['sim-range'].reset()
        },

        onAddSIMRange() {
            this.dialogs.sim_range.add.show = true
        },
        onDialogAddSIMRangeClose() {
            this.dialogs.sim_range.add.show = false
        },
        onDialogAddSIMRangeConfirm() {
            this.getSIMRanges()

            this.dialogs.sim_range.add.show = false
        },

        onRemoveSIMRange(row) {
            this.dialogs.sim_range.remove.uuid = row.uuid
            this.dialogs.sim_range.remove.show = true
        },
        onDialogSIMRangeRemovalClose() {
            this.dialogs.sim_range.remove.uuid = null
            this.dialogs.sim_range.remove.show = false
        },
        onDialogSIMRangeRemovalConfirm() {
            this.getSIMRanges()

            this.dialogs.sim_range.remove.uuid = null
            this.dialogs.sim_range.remove.show = false
        }
    },

    computed: {
        ...mapGetters([
            'spid',
            'current_spid',
            'available_service_providers',

            'is_super_admin',

            '$mobile_size',
        ]),

        main_spid() {
            return +this.spid
        },

        is_main_spid() {
            return this.current_spid == this.main_spid
        },

        show_add_new_range_button() {
            return this.is_super_admin && this.is_main_spid
        },

        service_providers() {
            return this.available_service_providers.map(provider => ({
                value: provider.SPID,
                title: provider.Name,
            }))
        },

        rows() {
            return this.sim_ranges.map(range => ({
                uuid: range.UUID,

                SIMRangeStart:  range.SIMRangeStart  ? range.SIMRangeStart  : '?',
                SIMRangeEnd:    range.SIMRangeEnd    ? range.SIMRangeEnd    : '?',
                IMSIRangeStart: range.IMSIRangeStart ? range.IMSIRangeStart : '?',
                IMSIRangeEnd:   range.IMSIRangeEnd   ? range.IMSIRangeEnd   : '?',

                CreatedAtNanos: range.CreatedAtNanos ? formatDateNano(range.CreatedAtNanos) : '?',
                UpdatedAtNanos: range.UpdatedAtNanos ? formatDateNano(range.UpdatedAtNanos) : '?',
            }))
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-number-manager-sim-ranges {
    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 48px;

        .dropdown-select-box {
            display: flex;
            align-items: center;

            width: 100%;
            max-width: 375px;

            margin: 0 0 0 16px;

            & > span {
                flex-shrink: 0;

                margin-right: 16px;

                font-size: 18px;
                color: var(--color-table-secondary);
            }
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .sim-ranges-table {
        @include table-cols-width((150px, 150px, 150px, 150px, 150px, 150px, 48px, 48px), true, 2);
    }

    & > .container {
        & > .row {
            & > .col-12 {
                & > .actions {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .app-pagination,
                    .btn-add-new-range {
                        margin-top: 30px;
                    }

                    .app-pagination {
                        flex-grow: 1;

                        margin-right: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-number-manager-sim-ranges {
        .sim-ranges-table {
            @include table-cols-width((130px, 130px, 130px, 130px, 130px, 130px, 48px, 48px), true, 2);
        }

        & > .container {
            & > .row {
                & > .col-12 {
                    & > .actions {
                        flex-direction: column;

                        .app-pagination {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-number-manager-sim-ranges {
        .heading-box {
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: 16px;

            .dropdown-select-box {
                margin: 32px 0 0 0;

                & > span {
                    margin-right: 4px;

                    font-size: 16px;
                }
            }
        }

        .sim-ranges-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        & > .container {
            & > .row {
                & > .col-12 {
                    & > .actions {
                        .app-pagination,
                        .btn-add-new-range {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}
</style>