<template>
    <div class="app-btn-upload btn" :disabled="disabled">
        <slot></slot>

        <label :for="id"></label>

        <input type="file"
            :id="id"
            :accept="accept"

            @change="change"
        >
    </div>
</template>

<script>
export default {
    props: {
        disabled: { type: Boolean, default: false },
        accept:   { type: String,  default: '.jpg, .jpeg, .png, .gif' },
    },

    methods: {
        change($event) {
            const file = $event.target.files[0]

            if (file) {
                this.$emit('input', file.name)
                this.$emit('upload', file)
            }
        },
    },

    computed: {
        id() {
            return `app-btn-upload-${this._uid}`
        },
    },
}
</script>

<style lang="scss">
.app-btn-upload {
    position: relative;

    label {
        position: absolute;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        opacity: 0;

        cursor: inherit;
    }

    input {
        display: none;
    }
}
</style>